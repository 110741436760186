const HashedId = require('./getHashedId');

// returns true if cache is updated
const addHashedIdToProperties = (idType, payload, suppressCallback) => {
    const { cacheUpdated, hashedId } = HashedId.getHashedIdAndUpdateCache(idType, payload, suppressCallback);

    if (hashedId) {
        payload.obj.properties = payload.obj.properties || {};
        payload.obj.properties[idType + 'Hashed'] = hashedId;

        return cacheUpdated;
    }
    return false;
};

// returns true if cache is updated
const addHashedIdToContext = (idType, payload, suppressCallback) => {
    const { cacheUpdated, hashedId } = HashedId.getHashedIdAndUpdateCache(idType, payload, suppressCallback);

    if (hashedId) {
        payload.obj.context = payload.obj.context || {};
        payload.obj.context.hashedIds = payload.obj.context.hashedIds || {};
        payload.obj.context.hashedIds[idType + 'Hashed'] = hashedId;

        return cacheUpdated;
    }
    return false;
};

const hashIdsBase = (payload, next, addHashedId) => {
    if (!payload || !payload.obj) {
        next(payload);
        return;
    }

    try {
        const hasSetUserId = addHashedId('userId', payload);
        addHashedId('anonymousId', payload, hasSetUserId);
    } catch (e) {
        console.log(e);
    } finally {
        next(payload);
    }
};

const hashIdsInProperties = ({ payload, next }) => {
    hashIdsBase(payload, next, (idType, payload, suppressCallback) =>
        addHashedIdToProperties(idType, payload, suppressCallback)
    );
};

const hashIdsInContext = ({ payload, next }) => {
    hashIdsBase(payload, next, (idType, payload, suppressCallback) =>
        addHashedIdToContext(idType, payload, suppressCallback)
    );
};

module.exports = {
    hashIdsInProperties,
    hashIdsInContext
};
