// This is copy of original Google tag manager Segment destination Google tag manager integration https://github.com/segmentio/analytics.js-integrations/blob/master/integrations/google-tag-manager/lib/index.js
// Added support for CSP nonce, nonce needs to be passed as part of integration options
const integration = typeof window !== 'undefined' ? require('@segment/analytics.js-integration') : () => {};
const globalQueue = require('global-queue');
const { registerThirdParty } = require('./thirdPartyLoader');

const push = globalQueue('dataLayer', { wrap: false });

function instatiateIntegration() {
    const GTM = integration('Google Tag Manager')
        .global('dataLayer')
        .global('google_tag_manager')
        .option('containerId', '')
        .option('environment', '')
        .option('trackNamedPages', true)
        .option('trackCategorizedPages', true)
        .option('nonce', null)
        .tag('no-env', `<script src="//www.googletagmanager.com/gtm.js?id={{ containerId }}&l=dataLayer">`)
        .tag(
            'with-env',
            `<script src="//www.googletagmanager.com/gtm.js?id={{ containerId }}&l=dataLayer&gtm_preview={{ environment }}">`
        )
        .tag(
            'no-env-nonce',
            `<script src="//www.googletagmanager.com/gtm.js?id={{ containerId }}&l=dataLayer" nonce="{{ nonce }}">`
        )
        .tag(
            'with-env-nonce',
            `<script src="//www.googletagmanager.com/gtm.js?id={{ containerId }}&l=dataLayer&gtm_preview={{ environment }}" nonce="{{ nonce }}">`
        );

    /**
     * Initialize.
     *
     * https://developers.google.com/tag-manager
     *
     * @api public
     */
    GTM.prototype.initialize = function () {
        push({ 'gtm.start': Number(new Date()), event: 'gtm.js' });

        registerThirdParty(() => {
            if (this.options.nonce) {
                if (this.options.environment.length) {
                    this.load('with-env-nonce', this.options, this.ready);
                } else {
                    this.load('no-env-nonce', this.options, this.ready);
                }
            } else {
                if (this.options.environment.length) {
                    this.load('with-env', this.options, this.ready);
                } else {
                    this.load('no-env', this.options, this.ready);
                }
            }
        });
    };

    /**
     * Loaded?
     *
     * @api private
     * @return {boolean}
     */
    GTM.prototype.loaded = function () {
        return !!(window.dataLayer && Array.prototype.push !== window.dataLayer.push);
    };

    /**
     * Page.
     *
     * @api public
     * @param {Page} page
     */
    GTM.prototype.page = function (page) {
        const category = page.category();
        const name = page.fullName();
        const opts = this.options;

        // all
        if (opts.trackAllPages) {
            this.track(page.track());
        }

        // categorized
        if (category && opts.trackCategorizedPages) {
            this.track(page.track(category));
        }

        // named
        if (name && opts.trackNamedPages) {
            this.track(page.track(name));
        }
    };

    /**
     * Track.
     *
     * https://developers.google.com/tag-manager/devguide#events
     *
     * @api public
     * @param {Track} track
     */
    GTM.prototype.track = function (track) {
        const props = track.properties();
        const userId = this.analytics.user().id();
        const anonymousId = this.analytics.user().anonymousId();
        if (userId) props.userId = userId;
        if (anonymousId) props.segmentAnonymousId = anonymousId;
        props.event = track.event();

        push(props);
    };

    return GTM;
}

/**
 * Expose `GTM`.
 */

class DummyGtmIntegration {}

const GTM = typeof window !== 'undefined' ? instatiateIntegration() : DummyGtmIntegration;

module.exports = GTM;
