const urlParse = require('url-parse');

function getParameters(url, params) {
    const res = {};
    const query = urlParse(url, true).query;

    for (let i = 0; i < params.length; i++) {
        const param = params[i];
        res[param] = query[param] || '';
    }

    return res;
}

function getHashParameter(url, param) {
    const hash = urlParse(url, true).hash;
    if (!hash) {
        return null;
    }

    const arr = hash.slice(1).split('&');
    for (let i = 0; i < arr.length; i++) {
        const key_value = arr[i].split('=');
        if (key_value.length == 2 && decodeURIComponent(key_value[0]) == param) {
            return decodeURIComponent(key_value[1]);
        }
    }

    return null;
}

module.exports = {
    getParameters: getParameters,
    getHashParameter: getHashParameter
};
