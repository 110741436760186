const CustomDomain = require('../customDomain');
const EventFactory = require('./eventFactory');
const { tenants } = require('./../constants');
const PageSharedPayloadMiddleware = require('../middlewares/pageSharedPayloadMiddleware');

/**
 * Segment analytics.js library is not able to send an event generated during unloading of a page.
 * That's why it was decided to use `navigator.sendBeacon`. An event is constructed manually.
 * A shared payload is a part of Page event, which should be sent earlier.
 */
class TrackingBeacon {
    constructor(writeKey, tenant, culture) {
        const domainConfig = CustomDomain.getConfig(writeKey);
        this._segmentTrackUrl = this._getSegmentTrackUrl(domainConfig);
        this._writeKey = writeKey;
        this._tenant = tenant || tenants.Vistaprint;
        this._culture = culture || 'en-IE';
    }

    track = (eventName, properties) => {
        const sharedPayload = PageSharedPayloadMiddleware.getPageSharedPayload();

        if (!sharedPayload) {
            return;
        }

        let payload = EventFactory.create(this._writeKey, eventName, {
            properties: {
                ...sharedPayload.properties,
                ...properties
            },
            culture: this._culture,
            tenant: this._tenant,
            sharedPayload
        });
        const sentAt = new Date();
        payload = { ...payload, sentAt };

        const blobData = new Blob([JSON.stringify(payload)], { type: 'text/plain' });

        navigator.sendBeacon(this._segmentTrackUrl, blobData);
    };

    _getSegmentTrackUrl(domainConfig) {
        const apiHost = domainConfig.apiHost ?? 'api.segment.io/v1';
        return `https://${apiHost}/t`;
    }
}

module.exports = TrackingBeacon;
