const browser = require('./browser');
const { getEmailIds } = require('@vp/promo-email-url-user-identifiers');

class ContextBuilder {
    constructor(context) {
        this.context = context || {};
    }

    static create(context) {
        return new ContextBuilder(context);
    }

    getContext() {
        return this.context;
    }

    addTimezone() {
        this.context.timezone = browser.getTimezone();

        return this;
    }

    addExternalIds() {
        const emailIds = getEmailIds();

        const externalIds = Object.entries(emailIds).map(([type, id]) => ({
            type,
            id,
            collection: 'users',
            encoding: 'none'
        }));

        if (externalIds.length > 0) {
            this.context.externalIds = externalIds;
        }

        return this;
    }
}

module.exports = ContextBuilder;
