const CustomEvent = require('custom-event');

function publish(type, value) {
    const event = new CustomEvent(type, value);
    window.dispatchEvent(event);
}

function publishAfterPageLoad(type, value) {
    if (performance.timing.loadEventEnd) {
        publish(type, value);
    } else {
        window.addEventListener('load', function () {
            publish(type, value);
        });
    }
}

function onBodyReady(callback) {
    if (document.body) {
        callback();
    } else {
        window.addEventListener('DOMContentLoaded', function () {
            callback();
        });
    }
}

module.exports = {
    publishAfterPageLoad: publishAfterPageLoad,
    onBodyReady: onBodyReady
};
