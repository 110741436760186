const { Sha256 } = require('@aws-crypto/sha256-js');

function hashSHA256(message) {
    if (!message) {
        return undefined;
    }

    const hash = new Sha256();
    hash.update(message);
    const result = hash.digestSync();
    return btoa(String.fromCharCode.apply(null, result));
}

module.exports = {
    hashSHA256
};
