const mapping = new Map([
    ['default', { domain: 'cdn.segment.com', apiHost: undefined, useAlias: false }],
    [
        'usbx43luh92dwclbx69wkgacfo3cwskt',
        { domain: 'evs.dcp.vpdcp.com', apiHost: 'api.dcp.vpdcp.com/v1', useAlias: true }
    ],
    [
        'nfnobbx9uv4bkzrlkdjopujkov4aohan',
        { domain: 'evs.dcp.vpdcp.com', apiHost: 'api.dcp.vpdcp.com/v1', useAlias: true }
    ],
    [
        // vistaprint-in
        'nff8ycs0umjbfe6izdnsksagczz0u6dh',
        { domain: 'evs.dcp.vpdcp.com', apiHost: 'api.dcp.vpdcp.com/v1', useAlias: true }
    ],
    [
        // vistaprint-default
        '4tpvtma75hfvbre6g2fixmlqj6yuo21v',
        { domain: 'evs.dcp.vpdcp.com', apiHost: 'api.dcp.vpdcp.com/v1', useAlias: true }
    ],
    [
        // vistaprint-main
        'suhmnezhrgtny6dfgaldgobjna0gqvdq',
        { domain: 'evs.dcp.vpdcp.com', apiHost: 'api.dcp.vpdcp.com/v1', useAlias: true }
    ],
    [
        // promotique-default
        'xxmofw4zj9ef461ngr6byycg0wku2u42',
        { domain: 'evs.dcp.vpdcp.com', apiHost: 'api.dcp.vpdcp.com/v1', useAlias: true }
    ],
    [
        // paypal-default
        'tbdfr8mfn4zyawz8jieav7mn8wyxwbbb',
        { domain: 'evs.dcp.vpdcp.com', apiHost: 'api.dcp.vpdcp.com/v1', useAlias: true }
    ],
    [
        // masks-default
        'qvax8zsa4zuqeyz79wjqymgnfirrrkc8',
        { domain: 'evs.dcp.vpdcp.com', apiHost: 'api.dcp.vpdcp.com/v1', useAlias: true }
    ],
    [
        // vistaprint-dev
        'xofi3xmqtxu14pvwkao4ofgzggr95ift',
        { domain: 'evs.dcp.vpdcp.com', apiHost: 'api.dcp.vpdcp.com/v1', useAlias: true }
    ],
    [
        // vista-prod
        'fkz7vdd127fgt9l8dbd4kz2stuspe458',
        { domain: 'evs.dcp.vpdcp.com', apiHost: 'api.dcp.vpdcp.com/v1', useAlias: true }
    ],
    [
        // vista-dev
        'kxa1hj1qc6ass5ip322iro9gcxtgai4v',
        { domain: 'evs.dcp.vpdcp.com', apiHost: 'api.dcp.vpdcp.com/v1', useAlias: true }
    ],
    [
        // vcs-prod
        'qmmfhanbi4kvp1ksh6v30gzwdxycwhqf',
        { domain: 'evs.dcp.vpdcp.com', apiHost: 'api.dcp.vpdcp.com/v1', useAlias: true }
    ],
    [
        // vcs-dev
        'przh7tcjveuxj9wenqqctr82gbpehx9v',
        { domain: 'evs.dcp.vpdcp.com', apiHost: 'api.dcp.vpdcp.com/v1', useAlias: true }
    ]
]);

function getConfig(writeKey) {
    const mappingKey = getMappingKey(writeKey);

    if (mapping.has(mappingKey)) {
        return mapping.get(mappingKey);
    }

    return mapping.get('default');
}

function getMappingKey(writeKey) {
    return writeKey.toLowerCase();
}

module.exports = {
    getConfig
};
