const Events = require('./events');
const Auth = require('./auth');
const Analytics = require('./analytics');
const Tracking = require('./tracking');
const WebVitals = require('./webVitals');
const { version } = require('./libraryInfo');
const Globals = require('./globals');
const DefaultMiddlewares = require('./middlewares');

function initialize(config, beforeTrackingInit) {
    console.log(`@vp/tracking:${version}`);

    if (!config || !config.writeKey) {
        console.error(`Write key is not provided for tracking purpose.`);
        return;
    }

    if (Globals.isTrackingInitialized()) {
        console.warn('Tracking is already initialized on the page, skipping initialization');
        return;
    }

    Globals.setTrackingInitialized();

    includeDefaultMiddlewares(config);

    Analytics.init(config, Tracking.setIntegrations);

    if (beforeTrackingInit) {
        beforeTrackingInit();
    }

    const tracking = Tracking.init(config.shouldTrack, config.culture, config.tenant);

    if (!config.disableWebVitals) {
        WebVitals.init(config.writeKey, config.tenant, config.culture);
    }

    Auth.init();

    Events.publishAfterPageLoad('trackingReady');

    return tracking;
}

function includeDefaultMiddlewares(config) {
    DefaultMiddlewares.init(config);
    config.sourceMiddlewares = DefaultMiddlewares.mergeSourceMiddlewares(config.sourceMiddlewares);
    config.destinationMiddlewares = DefaultMiddlewares.mergeDestinationMiddlewares(config.destinationMiddlewares);
}

module.exports = {
    init: initialize
};
