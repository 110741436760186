const Globals = require('../../globals');

const disableIntegrations = (originalIntegrations) => {
    const integrations = { ...originalIntegrations };
    for (const integration in integrations) {
        integrations[integration] = false;
    }
    integrations['Segment.io'] = true;
    integrations['Repeater'] = true;
    return integrations;
};

const sendIdentifiesWithIds = (id, hashedId, callbackData) => {
    const context = {
        integrations: disableIntegrations(callbackData.integrations),
        externalIds: [
            {
                id: hashedId,
                type: callbackData.idType + 'Hashed',
                collection: 'users'
            }
        ]
    };
    if (callbackData.idType === 'anonymousId') {
        context.anonymousId = id;
    }

    const tracking = Globals.getTracking();
    tracking.identify(callbackData.idType === 'userId' ? id : undefined, {}, context);
};

const getIdFromAnalytics = (idType) => {
    const normalizedIdType = idType === 'userId' ? 'id' : idType;

    const user = window.analytics && typeof window.analytics.user === 'function' ? window.analytics.user() : undefined;

    if (user && typeof window.analytics.user()[normalizedIdType] === 'function') {
        return window.analytics.user()[normalizedIdType]();
    }

    return undefined;
};

module.exports = {
    disableIntegrations,
    sendIdentifiesWithIds,
    getIdFromAnalytics
};
