import { init as _init } from '../init';
import Configuration from '../configuration';
import Globals from '../globals';
import ConsentManager from '../consentManager';

const getConfiguration = async (tenant, locale, environment) => {
    const path = `${tenant.toLowerCase()}-${locale.toLowerCase()}-${environment.toLowerCase()}`;

    // SystemJS is present when using SystemJS module format
    // eslint-disable-next-line no-undef
    return __system_context__.import(`@vp/tracking/config/${path}.json`).then((m) => m.default ?? m);
};

/**
 * Initializes the tracking library with the provided options.
 * @param {Object} options - The options for initializing the tracking library.
 * @param {string} options.tenant - The tenant identifier.
 * @param {string} options.culture - The culture identifier.
 * @param {'dev' | 'prod' | undefined} options.environment - The environment identifier

 * @param {string | undefined } options.writeKey - The write key.
 * @param {boolean | undefined} options.suppressReloadOnConsentSubmit - Whether to suppress page reload on consent submit.
 * @param {string[] | undefined} options.consentManagerCssClassNames - The CSS class names for the consent manager.
 * @param {string[] | undefined} options.integrations - The list of integrations to enable.
 * @param {string[] | undefined} options.sourceMiddlewares - The middlewares for source events.
 * @param {string[] | undefined} options.destinationMiddlewares - The middlewares for destination events.
 * @param {boolean | undefined} options.disableWebVitals - Whether to disable web vitals tracking.
 * @param {string | undefined} options.scriptNonce - Script nonce will be used for inlined scripts.
 * @param {string[] | undefined} options.featureFlags - Feature flags used to enable features in tracking currently "tracking_analytics_next".
 * @param {((error: TrackingError) => void) | undefined} options.onError - A callback function which will be invoked in case of problem with event tracking
 * @returns {Promise<{ tracking: Tracking, loadThirdParty: Promise<() => void> }>} A promise that resolves when the tracking library is initialized.
 */

export async function initTracking(options) {
    if (!options) {
        throw new Error('Missing options for tracking initialization');
    }

    const {
        tenant = 'vistaprint',
        writeKey,
        culture,
        sourceMiddlewares,
        destinationMiddlewares,
        suppressReloadOnConsentSubmit,
        consentManagerCssClassNames,
        disableWebVitals,
        environment = 'prod',
        onError,
        scriptNonce,
        featureFlags
    } = options;
    const localeConfiguration = await getConfiguration(tenant, culture, environment).catch((e) => {
        console.error('Failed to load locale configuration', e);
        throw e;
    });

    const integrations = Configuration.ensureIntegrations(options.integrations);
    const useConsentIntegrations = false;

    const config = Configuration.applyOverride(
        writeKey ?? localeConfiguration.writeKey,
        integrations,
        tenant,
        culture,
        sourceMiddlewares,
        destinationMiddlewares,
        suppressReloadOnConsentSubmit,
        onError,
        consentManagerCssClassNames,
        disableWebVitals,
        useConsentIntegrations,
        scriptNonce,
        featureFlags
    );

    let resolveThirdPartyLoader;

    const loadThirdParty = new Promise((resolve) => {
        resolveThirdPartyLoader = resolve;
    });

    config.setThirdPartyLoader = (loader) => {
        let once = true;
        resolveThirdPartyLoader(() => {
            if (once) {
                once = false;
                loader();
            }
        });
    };

    const tracking = _init(config, () => {
        ConsentManager.init(
            config.writeKey,
            config.tenant,
            config.culture,
            config.suppressReloadOnConsentSubmit,
            config.consentManagerCssClassNames,
            localeConfiguration.data
        );
    });

    Globals.setTracking(tracking);

    return {
        tracking,
        loadThirdParty
    };
}

/**
 * Retrieves the tracking instance.
 * @returns {Object} The tracking instance.
 */
export function getTracking() {
    return Globals.getTracking();
}
