const noTrack = '(+NoTrack)';
const siteTest = '(+SiteTest)';

function hasNoTrack() {
    if (navigator && navigator.userAgent) {
        return navigator.userAgent.indexOf(noTrack) > -1;
    }

    return false;
}

function hasSiteTest() {
    if (navigator && navigator.userAgent) {
        return navigator.userAgent.indexOf(siteTest) > -1;
    }

    return false;
}

module.exports = {
    hasNoTrack: hasNoTrack,
    hasSiteTest: hasSiteTest,
    noTrack: noTrack,
    siteTest: siteTest
};
