const VistaprintAuth = require('@vp/auth');
const Globals = require('./globals');

function init() {
    const auth = new VistaprintAuth.WebAuth();
    const identity = auth.getIdentity();
    if (identity && identity.profile) {
        setIdentity(identity);
    } else {
        // wait for auth initialization
        window.addEventListener('userIdentity', handleIdentityUpdate);
    }
}

function handleIdentityUpdate(e) {
    const identity = e.detail;
    if (identity && identity.profile) {
        setIdentity(identity);
        window.removeEventListener('userIdentity', handleIdentityUpdate);
    }
}

function setIdentity(identity) {
    const tracking = Globals.getTracking();
    if (identity.isSignedIn) {
        tracking.identify(getProfileId(identity.profile));
    } else {
        tracking.setAnonymousId(getProfileId(identity.profile));
    }
}

function getProfileId(profile) {
    return profile['https://claims.cimpress.io/canonical_id'];
}

module.exports = {
    init: init
};
