const cookieUtil = require('./cookieUtil');
const { v4: uuidv4 } = require('uuid');
const cookieLifetime = 30 * 60000; //30 minutes

function getVisit() {
    return cookieUtil.getCookie('visit') || null;
}

function ensureVisit() {
    let currentVisit = getVisit();

    if (!currentVisit) {
        currentVisit = generateVisit();
    }

    cookieUtil.setCookie('visit', currentVisit, { expires: getExpirationDate() });

    return currentVisit;
}

function getExpirationDate() {
    return new Date(Date.now() + cookieLifetime);
}

function generateVisit() {
    return uuidv4();
}

module.exports = {
    getVisit: getVisit,
    ensureVisit: ensureVisit
};
