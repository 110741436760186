const idHashingMiddleware = require('./idHashingMiddleware');
const messageSizeValidationMiddleware = require('./messageSizeValidationMiddleware');
const pageSharedPayloadMiddleware = require('./pageSharedPayloadMiddleware');
const addConsentCategoriesMiddleware = require('./addConsentCategoriesMiddleware');

const sourceMiddlewareProviders = [
    messageSizeValidationMiddleware,
    idHashingMiddleware,
    addConsentCategoriesMiddleware,
    pageSharedPayloadMiddleware
];
const destinationMiddlewareProviders = [idHashingMiddleware];

module.exports = {
    mergeSourceMiddlewares: (configSourceMiddlewares) => {
        let resultSourceMiddlewares = configSourceMiddlewares || [];

        if (sourceMiddlewareProviders && sourceMiddlewareProviders.length > 0) {
            sourceMiddlewareProviders.forEach((sourceMiddlewareProvider) => {
                resultSourceMiddlewares = resultSourceMiddlewares.concat(
                    sourceMiddlewareProvider.getSourceMiddlewares()
                );
            });
        }

        return resultSourceMiddlewares;
    },
    mergeDestinationMiddlewares: (configDestinationMiddlewares) => {
        let resultDestinationMiddlewares = configDestinationMiddlewares || [];

        if (destinationMiddlewareProviders && destinationMiddlewareProviders.length > 0) {
            destinationMiddlewareProviders.forEach((destinationMiddlewareProvider) => {
                resultDestinationMiddlewares = resultDestinationMiddlewares.concat(
                    destinationMiddlewareProvider.getDestinationMiddlewares()
                );
            });
        }

        return resultDestinationMiddlewares;
    },
    init: (config) => {
        messageSizeValidationMiddleware.init(config);
        idHashingMiddleware.init();
    }
};
