const CORE_VITAL_METRICS = new Set(['cls', 'fid', 'lcp', 'inp', 'fcp', 'ttfb']);

class MetricsCollector {
    constructor() {
        this._metricsCollection = new Set();
        this._latestMetrics = {};
    }

    addMetric(metric) {
        if (!this._isCoreVitalMetric(metric)) {
            return;
        }
        const trackingMetric = this._getTrackingMetric(metric);
        const latestTrackingMetric = this._getLatestTrackingMetric(metric);

        this._metricsCollection.add(trackingMetric);
        this._latestMetrics[trackingMetric.name] = latestTrackingMetric;
    }

    getAllMetrics() {
        return {
            allMetrics: [...this._metricsCollection],
            latest: { ...this._latestMetrics }
        };
    }

    newMetricsAvailable() {
        return this._metricsCollection.size > 0;
    }

    clear() {
        this._metricsCollection.clear();
    }

    _isCoreVitalMetric(metric) {
        return metric.name && CORE_VITAL_METRICS.has(metric.name.toLowerCase());
    }

    _getTrackingMetric(metric) {
        const { id, name, value, delta, navigationType } = metric;
        return { id, name: name.toLowerCase(), value, delta, navigationType };
    }

    _getLatestTrackingMetric(metric) {
        const { id, value, delta, navigationType } = metric;
        return { id, value, delta, navigationType };
    }
}

module.exports = MetricsCollector;
