// This wrapper is used to wrap the analytics object, to limit exposed methods and provide our own load method.
// Once everything is loaded we replace window.analytics with instance of Analytics which is returned from AnalyticsBrowser.load().

class AnalyticsWrapper {
    analytics;
    analyticsLoader;

    constructor(analytics, analyticsLoader) {
        this.analytics = analytics;
        this.analyticsLoader = analyticsLoader;
    }

    page(name, properties, options) {
        this.analytics.page(name, properties, options);
    }

    track(name, properties, options) {
        this.analytics.track(name, properties, options);
    }

    trackLink(element, eventName, properties) {
        this.analytics.trackLink(element, eventName, properties);
    }

    identify(userId, traits, options) {
        this.analytics.identify(userId, traits, options);
    }

    reset() {
        this.analytics.reset();
    }

    ready(callback) {
        this.analytics.ready(callback);
    }

    debug(debug) {
        this.analytics.debug(debug);
    }

    original() {
        return this.analytics;
    }

    load(writeKey, options) {
        this.analyticsLoader(writeKey, options);
    }
}

module.exports = AnalyticsWrapper;
