const TrackingPreferences = require('../helpers/fetchTrackingPreferences');

const addConsentCategoriesMiddleware = ({ payload, next }) => {
    if (!payload || !payload.obj) {
        next(payload);
        return;
    }

    try {
        addConsentCategoriesObject(payload);
    } catch (e) {
        console.log(e);
    } finally {
        next(payload);
    }
};
const addConsentCategoriesObject = (payload) => {
    try {
        const categoryPreferences = TrackingPreferences.getCategoryPreferences();
        if (categoryPreferences) {
            payload.obj.context = {
                ...payload.obj.context,
                consent: { categoryPreferences }
            };
        }
    } catch (e) {
        console.error(e);
    }
};

module.exports = {
    getSourceMiddlewares: () => {
        return [addConsentCategoriesMiddleware];
    }
};
