const WebVitals = require('web-vitals');
const MetricsCollector = require('./metricsCollector');
const NavigatorUtils = require('./navigatorUtils');

class WebVitalsTracker {
    constructor(tracking) {
        this._tracking = tracking;
        this._metricsCollector = new MetricsCollector();
        this._registerWebVitalsEvents();
    }

    registerUnloadEvents() {
        // Report all available metrics whenever the page is backgrounded or unloaded.
        window.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden') {
                this._trackCollectedMetrics();
            }
        });

        // NOTE: Safari does not reliably fire the `visibilitychange` event when the
        // page is being unloaded. If Safari support is needed, you should also flush
        // the queue in the `pagehide` event.
        if (!NavigatorUtils.isVisibilitychangeFullySupported()) {
            window.addEventListener('pagehide', () => {
                this._trackCollectedMetrics();
            });
        }
    }

    _registerWebVitalsEvents() {
        WebVitals.onCLS((metric) => this._addMetric(metric));
        WebVitals.onFCP((metric) => this._addMetric(metric));
        WebVitals.onFID((metric) => this._addMetric(metric));
        WebVitals.onINP((metric) => this._addMetric(metric));
        WebVitals.onLCP((metric) => this._addMetric(metric));
        WebVitals.onTTFB((metric) => this._addMetric(metric));
    }

    _addMetric(metric) {
        this._metricsCollector.addMetric(metric);
    }

    _trackCollectedMetrics() {
        if (this._metricsCollector.newMetricsAvailable()) {
            const webVitals = this._metricsCollector.getAllMetrics();
            this._tracking.track('Web Vitals Captured', { webVitals });
            this._metricsCollector.clear();
        }
    }
}

module.exports = WebVitalsTracker;
