/**
 * This middleware is used to store the shared payload of the Page event.
 * The shared payload is used to send `Web Vitals Captured` track event.
 * It is used due to limitation of Segment analytics.js library. It is not able to send an event generated during unloading of a page.
 */

const { commonEventProperties } = require('../constants');

let pageSharedPayload = undefined;

const storePageSharedPayloadMiddleware = ({ payload, next }) => {
    try {
        if (payload.obj?.type === 'page') {
            const { integrations, userId, anonymousId, context } = payload.obj;
            const commonProperties = getCommonProperties(payload.obj?.properties);
            pageSharedPayload = { integrations, userId, anonymousId, context, properties: commonProperties };
        }
    } finally {
        next(payload);
    }
};

const getCommonProperties = (properties) => {
    if (!properties) {
        return {};
    }

    const commonProperties = {};

    commonEventProperties.forEach((commonPropertyName) => {
        const propertyValue = properties[commonPropertyName];
        if (typeof propertyValue === 'string') {
            commonProperties[commonPropertyName] = propertyValue;
        }
    });

    return commonProperties;
};

const getMiddlewareDefinitions = () => {
    return [storePageSharedPayloadMiddleware];
};

const getPageSharedPayload = () => {
    if (!pageSharedPayload) {
        return undefined;
    }

    return { ...pageSharedPayload };
};

const init = () => {
    pageSharedPayload = undefined;
};

module.exports = {
    getPageSharedPayload,
    getSourceMiddlewares: getMiddlewareDefinitions,
    init
};
