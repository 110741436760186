const PropertyBuilder = require('../propertyBuilder');
const uuid = require('uuid');
const TrackingPreferences = require('../helpers/fetchTrackingPreferences');
const { removeAllHyphens } = require('../utils/stringUtils');
const { commonEventProperties } = require('../constants');

const create = (writeKey, eventName, { properties, culture, tenant, sharedPayload }) => {
    const timestamp = new Date();

    properties = getProperties(properties, culture, tenant, eventName);

    const categoryPreferences = TrackingPreferences.getCategoryPreferences();
    if (categoryPreferences) {
        sharedPayload.context = { ...sharedPayload.context, consent: { categoryPreferences } };
    }

    const payload = {
        ...sharedPayload,
        event: eventName,
        type: 'track',
        properties,
        timestamp,
        writeKey: writeKey,
        messageId: generateMessageId()
    };
    return payload;
};

const addConnectionInformation = (properties) => {
    // navigator.connection is not supported by Safari
    // eslint-disable-next-line compat/compat
    const connectionInfo = window?.navigator?.connection;
    if (!connectionInfo) {
        return properties;
    }

    const { rtt, effectiveType, downlink } = connectionInfo;
    const connection = {
        rtt,
        downlink,
        effectiveType
    };

    return { ...properties, connection };
};

const getProperties = (properties, culture, tenant, eventName) => {
    properties = PropertyBuilder.create(properties, culture, tenant)
        .addVisit()
        .addPtid()
        .addPageViewId()
        .addMetatagValues(commonEventProperties)
        .addLanguageLocale('track', eventName)
        .addTrackingInfo()
        .addTestUserId()
        .getProperties();

    properties = addConnectionInformation(properties);
    return properties;
};

const generateMessageId = () => `ajs-next-${removeAllHyphens(uuid.v4())}`;

module.exports = { create };
