const Integrations = require('../analyticsIntegrations');

function prepareIntegrations(consentIntegrations, pageIntegrations, apiHost, useConsentIntegrations) {
    let integrations = (useConsentIntegrations && consentIntegrations) || { All: true };
    integrations = Integrations.mergeIntegrations(integrations, pageIntegrations);

    const isNonEmptyWhitelist = Integrations.isNonEmptyWhitelist(integrations);

    if (apiHost) {
        Integrations.addApiHost(integrations, 'Segment.io', apiHost);
    } else if (isNonEmptyWhitelist) {
        Integrations.addIntegration(integrations, 'Segment.io');
    }

    if (isNonEmptyWhitelist) {
        Integrations.addIntegration(integrations, 'Repeater');
    }

    return integrations;
}

module.exports = { prepareIntegrations };
