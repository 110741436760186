const IdHashingStorage = require('./idHashingStorage');
const { hashSHA256 } = require('../../utils/crypto');
const Utils = require('./utils');

const getHashedIdAndUpdateCache = (idType, payload, suppressCallback) => {
    const idValue = Utils.getIdFromAnalytics(idType);

    if (!idValue) {
        return {
            cacheUpdated: false,
            hashedId: undefined
        };
    }

    const storageId = IdHashingStorage.get(idValue);

    const hashedId = storageId || hashSHA256(idValue);

    if (!storageId) {
        const callbackData = {
            idType,
            suppressCallback,
            integrations: payload.integrations()
        };
        IdHashingStorage.set(idValue, hashedId, callbackData);
        return {
            cacheUpdated: true,
            hashedId: hashedId
        };
    }
    return {
        cacheUpdated: false,
        hashedId: hashedId
    };
};

module.exports = {
    getHashedIdAndUpdateCache
};
