const thirdParties = [];

let loadImmediately = false;

function registerThirdParty(thirdParty) {
    if (loadImmediately) {
        thirdParty();
    }

    thirdParties.push(thirdParty);
}

function loadThirdParties() {
    loadImmediately = true;

    thirdParties.forEach((thirdParty) => {
        thirdParty();
    });
}

module.exports = { registerThirdParty, loadThirdParties };
