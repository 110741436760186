const Document = require('./document');
const { parseCulture, validateLanguage, validateLocale } = require('./culture');
const Visit = require('./visit');
const Url = require('./url');
const AbTestCookieManager = require('@vp/ab-test-cookie');
const libraryInfo = require('./libraryInfo');
const pageViewId = require('./pageViewId');
const { v4: uuidv4 } = require('uuid');

class PropertyBuilder {
    constructor(properties, culture, tenant) {
        this.properties = properties || {};
        this.culture = culture;
        this.tenant = tenant;
    }

    static create(properties, culture, tenant) {
        return new PropertyBuilder(properties, culture, tenant);
    }

    getProperties() {
        return this.properties;
    }

    addVisit() {
        this.properties.visit = Visit.ensureVisit();

        return this;
    }

    addPtid() {
        this.properties.ptid = uuidv4();
        return this;
    }

    addPageViewId() {
        this.properties.pageViewId = pageViewId.getPageViewId();
        return this;
    }

    addChannel() {
        if (window.location) {
            const url = window.location.toString();
            const params = Url.getParameters(url, ['tid', 'partner', 'utm_id']);

            if (params.tid) {
                this.properties.tid = params.tid;
            }

            if (params.partner) {
                this.properties.partner = params.partner;
            }

            if (params.utm_id) {
                this.properties.utm_id = params.utm_id;
            }
        }

        return this;
    }

    addMetatagValues(names) {
        const keyValues = Document.getMetaTagValues(names);
        if (keyValues) {
            keyValues.forEach((kv) => {
                if (!this.properties[kv.key]) {
                    this.properties[kv.key] = kv.value;
                }
            });
        }

        return this;
    }

    addLanguageLocale(eventType, eventName = undefined) {
        const logError = (property, value) => {
            const eventDetails = eventName ? `'${eventName}' of type '${eventType}'` : `of type '${eventType}'`;

            console.error(`A tracking property '${property}' has invalid value '${value}' of an event ${eventDetails}`);
        };

        const language = this.properties.language?.toLowerCase();

        if (language) {
            this.properties.language = language;
        }

        if (language && !validateLanguage(language)) {
            logError('language', language);
            this.properties.language = undefined;
        }

        const locale = this.properties.locale?.toUpperCase();

        if (locale) {
            this.properties.locale = locale;
        }

        if (locale && !validateLocale(locale)) {
            logError('locale', locale);
            this.properties.locale = undefined;
        }

        const langLocale = parseCulture(this.culture);
        if (langLocale.language && !this.properties.language) {
            this.properties.language = langLocale.language;
        }

        if (langLocale.locale && !this.properties.locale) {
            this.properties.locale = langLocale.locale;
        }

        return this;
    }

    addTrackingInfo() {
        this.properties.trackingVersion = libraryInfo.version;

        if (this.tenant) {
            this.properties.trackingTenant = this.tenant;
        }

        return this;
    }

    addFiscalYear(date) {
        const month = date.getUTCMonth(); //ACHTUNG! Zero-based month!
        const year = date.getUTCFullYear();

        this.properties.fiscalYear = 'FY' + ((month > 5 ? year + 1 : year) % 100);

        return this;
    }

    addTestUserId() {
        this.properties.testUserId = AbTestCookieManager.ensureUserTestId();

        return this;
    }
}

module.exports = PropertyBuilder;
