const commonEventProperties = ['pageName', 'pageSection', 'pageStage', 'pageDept', 'pageSegmentation'];
const tenants = {
    Vistaprint: 'vistaprint',
    VCS: 'vcs'
};

module.exports = {
    commonEventProperties,
    tenants
};
