const isVisibilitychangeFullySupported = () => {
    const navigator = window.navigator;
    if (!navigator) {
        return;
    }
    return !isSafari(navigator.userAgent);
};

const isSafari = (userAgentString) => {
    const chromeAgent = userAgentString.indexOf('Chrome') > -1;
    const safariAgent = userAgentString.indexOf('Safari') > -1;

    return !chromeAgent && safariAgent;
};

module.exports = {
    isVisibilitychangeFullySupported
};
