const { hashIdsInProperties, hashIdsInContext } = require('./idHashingMiddleware');
const IdHashingStorage = require('./idHashingStorage');
const Utils = require('./utils');

const getIdHashingDestinationMiddlewareDefinitions = () => {
    return [
        { targetIntegration: 'Google Tag Manager', middlewares: [hashIdsInProperties] },
        { targetIntegration: 'Google Analytics', middlewares: [hashIdsInProperties] }
    ];
};

const getIdHashingSourceMiddlewareDefinitions = () => {
    return [hashIdsInContext];
};

const init = () => {
    IdHashingStorage.onSet(Utils.sendIdentifiesWithIds);
};

module.exports = {
    getDestinationMiddlewares: getIdHashingDestinationMiddlewareDefinitions,
    getSourceMiddlewares: getIdHashingSourceMiddlewareDefinitions,
    init
};
