const onload = require('window-load');
const Integrations = require('../analyticsIntegrations');
const { supportsConsentCategories } = require('../consentManager');
const segmentWorkspaceId = '9BNvi137tW';

function init(config, initCallback) {
    // Create a queue, but don't obliterate an existing one!
    const analytics = (window.analytics = window.analytics || []);

    // If the real analytics.js is already on the page return.
    if (analytics.initialize) return;

    // If the snippet was invoked already show an error.
    if (analytics.invoked) {
        if (window.console && console.error) {
            console.error('Segment snippet included twice.');
        }
        return;
    }

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true;

    // A list of the methods in Analytics.js to stub.
    analytics.methods = [
        'setAnonymousId',
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'addDestinationMiddleware'
    ];

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory = function (method) {
        return function () {
            const args = Array.prototype.slice.call(arguments);
            args.unshift(method);
            analytics.push(args);
            return analytics;
        };
    };

    // For each of our methods, generate a queueing stub.
    for (let i = 0; i < analytics.methods.length; i++) {
        const key = analytics.methods[i];
        analytics[key] = analytics.factory(key);
    }

    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    analytics.load = (key, options) => {
        onload(() => {
            options = options || {};
            const useConsentIntegrations = config.useConsentIntegrations || !supportsConsentCategories();
            options.integrations = prepareIntegrations(
                options.integrations,
                config.integrations,
                config.apiHost,
                useConsentIntegrations
            );
            analytics._writeKey = key;
            if (config.domain) {
                analytics._cdn = `https://${config.domain}`;
            }

            initCallback(options.integrations);
            analytics._loadOptions = options;
            loadMiddlewares(analytics, config.sourceMiddlewares, config.destinationMiddlewares);
            if (config.setThirdPartyLoader) {
                // provide a way to load the snippet asynchronously
                // which allows us to defer loading of third-party scripts
                // prioritizing the loading of the tracking facade, and the content
                config.setThirdPartyLoader(() => {
                    loadSnippet(key, config.domain, config.useAnalyticsAlias, config.scriptNonce);
                });
            } else {
                loadSnippet(key, config.domain, config.useAnalyticsAlias, config.scriptNonce);
            }
        });
    };

    // Add a version to keep track of what's in the wild.
    analytics.SNIPPET_VERSION = '4.13.2';
}

function prepareIntegrations(consentIntegrations, pageIntegrations, apiHost, useConsentIntegrations) {
    let integrations = (useConsentIntegrations && consentIntegrations) || { All: true };
    integrations = Integrations.mergeIntegrations(integrations, pageIntegrations);

    const isNonEmptyWhitelist = Integrations.isNonEmptyWhitelist(integrations);

    if (apiHost) {
        Integrations.addApiHost(integrations, 'Segment.io', apiHost);
    } else if (isNonEmptyWhitelist) {
        Integrations.addIntegration(integrations, 'Segment.io');
    }

    if (isNonEmptyWhitelist) {
        Integrations.addIntegration(integrations, 'Repeater');
    }

    return integrations;
}

function loadSnippet(writeKey, domain, useAlias, scriptNonce) {
    // Create an async script element based on your key.
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = buildSnippetUrl(writeKey, domain, segmentWorkspaceId, useAlias);

    if (scriptNonce) {
        script.nonce = scriptNonce;
    }

    // Insert our script next to the first script element.
    const first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);
}

function buildSnippetUrl(writeKey, domain, workspaceId, useAlias) {
    return useAlias
        ? `https://${domain}/${workspaceId}/${writeKey}.min.js`
        : `https://${domain}/analytics.js/v1/${writeKey}/analytics.min.js`;
}

function loadMiddlewares(analytics, sourceMiddlewares, destinationMiddlewares) {
    if (Array.isArray(sourceMiddlewares) && sourceMiddlewares.length > 0) {
        sourceMiddlewares.forEach((middleware) => {
            const wrappedMiddleware = wrapMiddlewareIntoTryCatch(middleware);
            analytics.addSourceMiddleware(wrappedMiddleware);
        });
    }

    if (Array.isArray(destinationMiddlewares) && destinationMiddlewares.length > 0) {
        destinationMiddlewares.forEach((middlewareDefinition) => {
            const wrappedMiddlewares = middlewareDefinition.middlewares.map((middleware) =>
                wrapMiddlewareIntoTryCatch(middleware)
            );
            analytics.addDestinationMiddleware(middlewareDefinition.targetIntegration, wrappedMiddlewares);
        });
    }
}

function wrapMiddlewareIntoTryCatch(middleware) {
    return (middlewareArgs) => {
        try {
            middleware(middlewareArgs);
        } catch (e) {
            console.error('Failed on executing a middleware', e);
            middlewareArgs.next(middlewareArgs.payload);
        }
    };
}

module.exports = {
    initAnalyticsSnippet: init
};
