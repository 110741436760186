function getMetaTagValues(names) {
    const array = [];
    const metatags = document.querySelectorAll('meta');

    for (let i = 0; i < metatags.length; i++) {
        const m = metatags[i];
        const key = m.getAttribute('name');
        if (names.indexOf(key) >= 0) {
            array.push({
                key: m.getAttribute('name'),
                value: m.getAttribute('content')
            });
        }
    }
    return array;
}

function getMetaTagValue(name) {
    if (!name) {
        return null;
    }

    const metatag = document.getElementsByTagName('meta')[name];

    if (!metatag) {
        return null;
    }

    return metatag.getAttribute('content');
}

module.exports = {
    getMetaTagValues: getMetaTagValues,
    getMetaTagValue: getMetaTagValue
};
