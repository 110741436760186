const storageKey = 'vp-tracking-hashed-ids';
let isInitialized = false;
let storage;
let onSetCallback;

const ensureInit = () => {
    if (!isInitialized) {
        try {
            const test = 'test';
            window.localStorage.setItem(test, test);
            window.localStorage.removeItem(test);
            storage = window.localStorage;
        } catch (e) {
            const storageMemory = {};
            storage = {
                getItem: (key) => storageMemory[key],
                setItem: (key, value) => (storageMemory[key] = value),
                removeItem: (key) => delete storageMemory[key]
            };
        }

        isInitialized = true;
    }
};

const get = (key) => {
    ensureInit();

    const storageItem = storage.getItem(storageKey);
    if (storageItem) {
        const hashedIds = JSON.parse(storageItem);
        return hashedIds[key];
    }
};

const set = (key, value, callbackData) => {
    ensureInit();

    const storageItem = storage.getItem(storageKey);
    const hashedIds = storageItem ? JSON.parse(storageItem) : {};
    hashedIds[key] = value;
    storage.setItem(storageKey, JSON.stringify(hashedIds));
    if (onSetCallback && (!callbackData || !callbackData.suppressCallback)) {
        onSetCallback(key, value, callbackData);
    }
};

const onSet = (callback) => (onSetCallback = callback);

const reset = () => {
    ensureInit();
    storage.removeItem(storageKey);
    onSetCallback = null;
};

module.exports = {
    get: get,
    set: set,
    onSet: onSet,
    reset: reset
};
