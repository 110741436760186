const { onBodyReady } = require('./events');
const consentManager = typeof window !== 'undefined' ? require('@vp/consent-manager') : null;
const bannerContainer = 'consent-manager-container';

function init(writeKey, tenant, culture, suppressReloadOnConsentSubmit, consentManagerCssClassNames, data) {
    if (typeof window === 'undefined') return;

    const props = {
        data,
        container: bannerContainer,
        writeKey: writeKey,
        shouldRequireConsent: () => consentManager.isConsentRequired(culture),
        hideBanner: isIframe(),
        tenant: tenant,
        locale: culture,
        suppressReload: suppressReloadOnConsentSubmit
    };

    onBodyReady(() => {
        const container = document.createElement('div');
        container.id = bannerContainer;
        if (consentManagerCssClassNames) {
            container.className = consentManagerCssClassNames;
        }
        document.body.appendChild(container);
        consentManager.initConsentManager(props);
    });
}

function supportsConsentCategories() {
    if (typeof window === 'undefined') return false;

    return !!window.consentManager?.getTrackingPreferences;
}

function isIframe() {
    try {
        return typeof window !== 'undefined' && window.self !== window.top;
    } catch (e) {
        return true;
    }
}

module.exports = {
    init: init,
    supportsConsentCategories
};
