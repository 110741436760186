const onload = require('window-load');
const WebVitalsTracker = require('./webVitalsTracker');
const TrackingBeacon = require('./trackingBeacon');

let tracker;

function init(writeKey, tenant, culture) {
    if (typeof window === 'undefined' || !window.navigator) {
        return;
    }

    onload(() => {
        const tracking = new TrackingBeacon(writeKey, tenant, culture);
        tracker = new WebVitalsTracker(tracking);
        tracker.registerUnloadEvents();
    });
}

module.exports = {
    init: init
};
