const languageRegex = new RegExp('^[a-z]{2}$');
const localeRegex = new RegExp('^[A-Z]{2}$');

function parseCulture(culture) {
    if (!culture) {
        return {};
    }

    const langLocale = culture.split('-');

    const language = langLocale[0],
        locale = langLocale.length > 1 ? langLocale[langLocale.length - 1] : undefined;

    return {
        language: language?.toLowerCase(),
        locale: locale?.toUpperCase()
    };
}

function validateLanguage(language) {
    return language && languageRegex.test(language);
}

function validateLocale(locale) {
    return locale && localeRegex.test(locale);
}

module.exports = {
    parseCulture,
    validateLanguage,
    validateLocale
};
