const Cookie = require('es-cookie');
const { getRootCookieDomain } = require('./domain');

function getCookie(cookieName) {
    return Cookie.get(cookieName);
}

function setCookie(cookieName, data, attributes = {}) {
    attributes = {
        domain: getRootCookieDomain(),
        ...attributes
    };
    Cookie.set(cookieName, data, attributes);
}

function removeCookie(cookieName) {
    Cookie.remove(cookieName);
}

module.exports = {
    getCookie,
    setCookie,
    removeCookie
};
