const { v4: uuidv4 } = require('uuid');

let pageViewId = null;

function refresh() {
    pageViewId = uuidv4();
    return pageViewId;
}

function getPageViewId() {
    if (!pageViewId) {
        pageViewId = uuidv4();
    }
    return pageViewId;
}

module.exports = {
    refresh,
    getPageViewId
};
