const onload = require('window-load');
const { AnalyticsBrowser } = require('@segment/analytics-next');
const { loadThirdParties } = require('./thirdPartyLoader');
const GoogleTagManagerIntegrationWithNonce = require('./googleTagManagerIntegration');
const AnalyticsWrapper = require('./analyticsWrapper');
const { loadMiddlewares } = require('./middlewareLoader');
const { updateCDNSettings } = require('./cdnSettingsHelper');
const { setGlobal } = require('./globalsHelper');
const { supportsConsentCategories } = require('../consentManager');
const { prepareIntegrations } = require('./integrationsHelper');

function initAnalytics(analyticsNextConfig, initCallback) {
    const analytics = new AnalyticsBrowser();

    const analyticsLoader = (writeKey, options) => {
        loadAnalytics(analytics, writeKey, options?.integrations, analyticsNextConfig, initCallback);
    };

    const analyticsWrapper = new AnalyticsWrapper(analytics, analyticsLoader);

    setGlobal(analyticsWrapper);
}

async function loadAnalytics(analytics, writeKey, consentIntegrations, analyticsNextConfig, initCallback) {
    const useConsentIntegrations = analyticsNextConfig.useConsentIntegrations || !supportsConsentCategories();
    const integrations = prepareIntegrations(
        consentIntegrations,
        analyticsNextConfig.integrations,
        analyticsNextConfig.apiHost,
        useConsentIntegrations
    );

    initCallback(integrations);

    const pciCompliance = !!analyticsNextConfig.scriptNonce;
    const cdnUrl = analyticsNextConfig.domain ? `https://${analyticsNextConfig.domain}` : undefined;

    loadMiddlewares(analytics, analyticsNextConfig.sourceMiddlewares, analyticsNextConfig.destinationMiddlewares);

    const loadedAnalytics = analytics.load(
        {
            writeKey: writeKey,
            classicIntegrations: [GoogleTagManagerIntegrationWithNonce], // this is the way how to import classic integrations through npm to avoid download from Segment CDN
            cdnURL: cdnUrl
        },
        {
            updateCDNSettings: (cdnSettings) =>
                updateCDNSettings(
                    cdnSettings,
                    analyticsNextConfig.scriptNonce,
                    pciCompliance,
                    analyticsNextConfig.pciCompliantGtmContainerId
                ),
            integrations: integrations
        }
    );

    loadedAnalytics.then(([analytics]) => setGlobal(analytics));

    if (analyticsNextConfig.setThirdPartyLoader) {
        // provide a way to load the snippet asynchronously
        // which allows us to defer loading of third-party scripts
        // prioritizing the loading of the tracking facade, and the content
        analyticsNextConfig.setThirdPartyLoader(() => {
            loadThirdParties();
        });
    } else {
        onload(() => {
            loadThirdParties();
        });
    }
}

module.exports = {
    initAnalytics
};
