const Visit = require('./visit');
const { mergeIntegrations } = require('./analyticsIntegrations');
const PropertyBuilder = require('./propertyBuilder');
const ContextBuilder = require('./contextBuilder');
const { version } = require('./libraryInfo');
const Globals = require('./globals');
const pageViewId = require('./pageViewId');
const { commonEventProperties, tenants } = require('./constants');

const analyticsInitTimeout = 10 * 1000; // 10 seconds
const authInitTimeout = 9 * 1000; // 9 seconds
let waitingForAuth = true;
let waitingForAnalytics = true;
let pageIntegrations = null;
const config = {
    shouldTrack: true,
    culture: 'en-IE',
    tenant: tenants.Vistaprint
};

function initializeTracking(shouldTrack = true, culture = 'en-IE', tenant = tenants.Vistaprint) {
    config.shouldTrack = shouldTrack;
    config.culture = culture;
    config.tenant = tenant;

    if (canTrack()) {
        setTimeout(() => {
            analyticsInitCheck();
        }, analyticsInitTimeout);

        setTimeout(() => {
            authInitCheck();
        }, authInitTimeout);
    }

    const commonProperties = [...commonEventProperties, ...(tenant === tenants.VCS ? ['storeId'] : [])];

    const tracking = {
        version: () => version,

        visit: () => Visit.getVisit(),

        page: (pageName, properties, options) => {
            pageViewId.refresh();

            runWithCheck(() => {
                if (typeof pageName === 'object') {
                    properties = pageName;
                    pageName = '';
                }

                properties = PropertyBuilder.create(properties, config.culture, config.tenant, 'page')
                    .addVisit()
                    .addPtid()
                    .addPageViewId()
                    .addChannel()
                    .addMetatagValues([...commonProperties, 'swanVersion', 'generator'])
                    .addLanguageLocale('page')
                    .addTrackingInfo()
                    .addFiscalYear(new Date())
                    .addTestUserId()
                    .getProperties();

                raw().page(pageName, properties, prepareOptions(options));
            });
        },

        track: (eventName, properties, options) => {
            runWithCheck(() => {
                properties = PropertyBuilder.create(properties, config.culture, config.tenant)
                    .addVisit()
                    .addPtid()
                    .addPageViewId()
                    .addMetatagValues(commonProperties)
                    .addLanguageLocale('track', eventName)
                    .addTrackingInfo()
                    .addTestUserId()
                    .getProperties();

                raw().track(eventName, properties, prepareOptions(options));
            });
        },

        trackLink: (element, eventName, properties) => {
            runWithCheck(() => {
                properties = PropertyBuilder.create(properties, config.culture, config.tenant)
                    .addVisit()
                    .addPtid()
                    .addPageViewId()
                    .addMetatagValues(commonProperties)
                    .addLanguageLocale('trackLink', eventName)
                    .addTrackingInfo()
                    .getProperties();

                raw().trackLink(element, eventName, properties);
            });
        },

        trackNavigation: (element, eventName, properties) => {
            runWithCheck(() => {
                properties = PropertyBuilder.create(properties, config.culture, config.tenant)
                    .addVisit()
                    .addPtid()
                    .addPageViewId()
                    .addMetatagValues(commonProperties)
                    .addLanguageLocale('trackNavigation', eventName)
                    .addTrackingInfo()
                    .getProperties();

                raw().trackLink(element, eventName, properties);
            });
        },

        identify: (userId, traits, options) => {
            if (!canTrack()) return;

            raw().identify(userId, traits || {}, addContext(options));

            waitingForAuth = false;
        },

        reset: () => {
            if (!hasAnalytics()) return;

            raw().reset();
        },

        setAnonymousId: (anonymousId) => {
            if (!hasAnalytics()) return;

            iterateUntilUserReady(() => {
                const user = raw().user();
                user.logout();
                user.anonymousId(anonymousId);

                waitingForAuth = false;
            }, 200);
        }
    };

    Globals.setTracking(tracking);

    return tracking;
}

function runWithCheck(func) {
    if (!canTrack()) return;

    iterateWithDelay(func, 200);
}

function iterateWithDelay(func, delay) {
    if (waitingForAnalytics && analyticsUserReady()) {
        waitingForAnalytics = false;
    }

    if (shouldSend()) {
        func();
    } else {
        setTimeout(() => iterateWithDelay(func, delay), delay);
    }
}

function iterateUntilUserReady(func, delay) {
    if (analyticsUserReady()) {
        waitingForAnalytics = false;
        func();
    } else {
        setTimeout(() => iterateUntilUserReady(func, delay), delay);
    }
}

function shouldSend() {
    return !waitingForAuth && !waitingForAnalytics;
}

function authInitCheck() {
    if (waitingForAuth) {
        console.warn(
            'It looks like auth library (@vp/auth) has not been initialized after ' +
                authInitTimeout +
                'ms since tracking.js was loaded. Events fired in this period have been published without any ID.'
        );
    }

    waitingForAuth = false;
}

function analyticsInitCheck() {
    if (waitingForAnalytics && !analyticsUserReady()) {
        console.warn(
            'Analytics library has not been fully initialized after ' +
                analyticsInitTimeout +
                'ms since tracking.js was loaded.'
        );
    }

    waitingForAnalytics = false;
}

function analyticsUserReady() {
    return raw().user && typeof raw().user === 'function';
}

function canTrack() {
    return hasAnalytics() && config.shouldTrack;
}

function hasAnalytics() {
    return !!(raw() && typeof raw() === 'object');
}

function raw() {
    return window.analytics;
}

function setIntegrations(integrations) {
    pageIntegrations = integrations;
}

function prepareOptions(options) {
    const res = {};

    if (options && options.integrations) {
        if (pageIntegrations) {
            res.integrations = mergeIntegrations(options.integrations, pageIntegrations);
        } else {
            res.integrations = options.integrations;
        }
    }

    addContext(res);

    return res;
}

function addContext(options) {
    options = options || {};
    options.context = ContextBuilder.create(options.context).addExternalIds().addTimezone().getContext();
    return options;
}

module.exports = {
    init: initializeTracking,
    setIntegrations: setIntegrations
};
