function getTimezone() {
    try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (error) {
        console.log('Tracking: Retrieve of timezone failed');
    }

    return undefined;
}

module.exports = {
    getTimezone: getTimezone
};
