function loadMiddlewares(analytics, sourceMiddlewares, destinationMiddlewares) {
    if (Array.isArray(sourceMiddlewares) && sourceMiddlewares.length > 0) {
        sourceMiddlewares.forEach((middleware) => {
            const wrappedMiddleware = wrapMiddlewareIntoTryCatch(middleware);
            analytics.addSourceMiddleware(wrappedMiddleware);
        });
    }

    if (Array.isArray(destinationMiddlewares) && destinationMiddlewares.length > 0) {
        destinationMiddlewares.forEach((middlewareDefinition) => {
            const wrappedMiddlewares = middlewareDefinition.middlewares.map((middleware) =>
                wrapMiddlewareIntoTryCatch(middleware)
            );
            analytics.addDestinationMiddleware(middlewareDefinition.targetIntegration, wrappedMiddlewares);
        });
    }
}

function wrapMiddlewareIntoTryCatch(middleware) {
    return (middlewareArgs) => {
        try {
            middleware(middlewareArgs);
        } catch (e) {
            console.error('Failed on executing a middleware', e);
            middlewareArgs.next(middlewareArgs.payload);
        }
    };
}

module.exports = {
    loadMiddlewares
};
