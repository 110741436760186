const Store = require('store');
const { getCurrentTenant } = require('@vp/tenant-resolution');
const { getCurrentCulture } = require('@vp/culture-resolution');
const CookieUtil = require('./cookieUtil');
const UserAgent = require('./userAgent');
const CustomDomain = require('./customDomain');
const noTrackingWriteKey = 'oOgyr9bTme0dtCxg9IxbESBjTNQQjxca';
const pciCompliantGtmContainerId = 'GTM-MQMB4G9R'; // vistaprint-analytics gtm container id

function load() {
    // Get config from script element data attribute "data-write-key"
    const scriptTag = document.getElementById('tracking-vp-dcp') || document.getElementById('tracking');
    const data = getDataAttributes(scriptTag);
    const useConsentIntegrations = true;
    return applyOverride(
        data.writeKey,
        data.integrations,
        data.tenant,
        data.culture,
        undefined,
        undefined,
        data.suppressReloadOnConsentSubmit,
        undefined,
        undefined,
        false,
        useConsentIntegrations,
        undefined,
        undefined
    );
}

function applyOverride(
    writeKey,
    integrations,
    tenant,
    culture,
    sourceMiddlewares,
    destinationMiddlewares,
    suppressReloadOnConsentSubmit,
    onError,
    consentManagerCssClassNames,
    disableWebVitals,
    useConsentIntegrations,
    scriptNonce,
    featureFlags
) {
    const storeWriteKey = getOverrideWriteKey();

    if (storeWriteKey) {
        writeKey = storeWriteKey;
        integrations = createEmptyBlackList();
    }

    tenant = tenant || getCurrentTenant();
    culture = culture || getCurrentCulture();

    const customDomainConfig = CustomDomain.getConfig(writeKey);

    return {
        writeKey: writeKey,
        integrations: integrations,
        tenant: tenant,
        culture: culture,
        shouldTrack: !UserAgent.hasNoTrack(),
        domain: customDomainConfig.domain,
        useAnalyticsAlias: customDomainConfig.useAlias,
        apiHost: customDomainConfig.apiHost,
        sourceMiddlewares: sourceMiddlewares,
        destinationMiddlewares: destinationMiddlewares,
        suppressReloadOnConsentSubmit: suppressReloadOnConsentSubmit === true, // sanity check for correct data type
        onError: typeof onError === 'function' ? onError : defaultOnError,
        consentManagerCssClassNames,
        disableWebVitals,
        useConsentIntegrations,
        scriptNonce,
        featureFlags,
        pciCompliantGtmContainerId
    };
}

function getOverrideWriteKey() {
    if (UserAgent.hasSiteTest()) {
        console.log('Tracking to testing source based on user agent');
        return noTrackingWriteKey;
    }

    const storeWriteKey = getStoreWriteKey();

    if (storeWriteKey) {
        console.log('Override write key has been detected in local storage');
        return storeWriteKey;
    }

    const cookieWriteKey = CookieUtil.getCookie('data-write-key');

    if (cookieWriteKey) {
        console.log('Override write key has been detected in cookie');
        return cookieWriteKey;
    }

    return undefined;
}

function ensureIntegrations(integrations) {
    if (!integrations || (Object.keys(integrations).length === 0 && integrations.constructor === Object)) {
        return createEmptyBlackList();
    }

    return integrations;
}

function getDataAttributes(scriptTag) {
    return {
        writeKey: getDataAttribute(scriptTag, 'write-key'),
        integrations: parseIntegrations(getDataAttribute(scriptTag, 'integrations')),
        tenant: getDataAttribute(scriptTag, 'tenant'),
        culture: getDataAttribute(scriptTag, 'culture'),
        suppressReloadOnConsentSubmit: getDataAttribute(scriptTag, 'suppressReloadOnConsentSubmit') === 'true'
    };
}

function parseIntegrations(integrationsAttribute) {
    let integrations;

    if (integrationsAttribute) {
        integrations = JSON.parse(integrationsAttribute.replace(/'/g, '"'));
    }

    return ensureIntegrations(integrations);
}

function getDataAttribute(element, name) {
    return element.getAttribute(`data-${name}`);
}

function getStoreWriteKey() {
    return Store.get('data-write-key');
}

function createEmptyBlackList() {
    return { All: true };
}

function defaultOnError(error) {
    console.error(JSON.stringify(error));
}

module.exports = {
    load: load,
    applyOverride: applyOverride,
    ensureIntegrations: ensureIntegrations,
    noTrackingWriteKey: noTrackingWriteKey
};
