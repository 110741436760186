const { initAnalyticsSnippet } = require('./analyticsSnippet');
const { initAnalyticsNext } = require('./analyticsNext');

const featureFlag = 'tracking_analytics_next';

function init(config, initCallback) {
    const isAnalyticsNextEnabled = Array.isArray(config.featureFlags) && config.featureFlags.includes(featureFlag);

    // enabled based on feature flag or when scriptNonce is available
    if (isAnalyticsNextEnabled || config.scriptNonce) {
        initAnalyticsNext(config, initCallback);
    } else {
        initAnalyticsSnippet(config, initCallback);
    }
}

module.exports = {
    init: init
};
