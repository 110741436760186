function mergeIntegrations(integrationsX, integrationsY) {
    const x = transformIntegrations(integrationsX);
    const y = transformIntegrations(integrationsY);

    if (x.isWhitelist && y.isWhitelist) {
        return buildIntegrations(false, getIntersection(x.items, y.items));
    }

    if (!x.isWhitelist && !y.isWhitelist) {
        return buildIntegrations(true, x.items.concat(y.items));
    }

    if (x.isWhitelist) {
        return buildIntegrations(false, getDifference(x.items, y.items));
    } else {
        return buildIntegrations(false, getDifference(y.items, x.items));
    }
}

function someEnabled(integrations) {
    const transformed = transformIntegrations(integrations);
    return !transformed.isWhitelist || transformed.items.length > 0;
}

function addIntegration(integrations, integration) {
    if (integrations && integration) {
        integrations[integration] = true;
    }
}

function addApiHost(integrations, integration, apiHost) {
    if (integrations && integration) {
        integrations[integration] = {
            apiHost: apiHost
        };
    }
}

function isNonEmptyWhitelist(integrations) {
    return integrations && integrations.All === false && Object.keys(integrations).length > 1;
}

function buildIntegrations(isBlacklist, items) {
    const integrations = { All: isBlacklist };

    for (let i = 0; i < items.length; i++) {
        integrations[items[i]] = !isBlacklist;
    }

    return integrations;
}

function transformIntegrations(integrations) {
    const isWhitelist = integrations.All === false;

    return {
        isWhitelist,
        items: getItems(integrations, isWhitelist)
    };
}

function getItems(integrations, isWhitelist) {
    return Object.keys(integrations).filter((k) => integrations[k] == isWhitelist && k != 'All');
}

function getIntersection(a, b) {
    return a.filter((x) => b.includes(x));
}

function getDifference(a, b) {
    return a.filter((x) => !b.includes(x));
}

module.exports = {
    mergeIntegrations,
    someEnabled,
    addIntegration,
    addApiHost,
    isNonEmptyWhitelist
};
