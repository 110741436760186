const messageSizeCorrection = 1024;
const messageLimit = 32 * 1024;
const messageWarningLimit = 24 * 1024;

let onError = null;

const messageSizeValidationMiddleware = ({ payload, next }) => {
    if (!payload || !payload.obj) {
        next(payload);
        return;
    }

    try {
        validateMessageSize(payload);
    } catch (e) {
        console.log(e);
    } finally {
        next(payload);
    }
};

const validateMessageSize = (payload) => {
    const serializedMessage = JSON.stringify(payload.obj);
    const blob = new Blob([serializedMessage]);
    let messageSize = 0;

    // make sure that size function is available on the blob
    if (typeof blob.size === 'undefined') {
        messageSize = serializedMessage.length;
    } else {
        messageSize = blob.size;
    }

    // correction of the message size, payload.obj doesn't contain some info added later (context.userAgent, context.library, context.locale, writeKey, sentAt, _metadata)
    messageSize = messageSize + messageSizeCorrection;

    if (messageSize >= messageLimit) {
        logError(payload, messageSize);
    } else if (messageSize > messageWarningLimit) {
        logWarning(payload, messageSize);
    }
};

const logWarning = (payload, messageSize) => {
    const eventName = getEventName(payload);
    const eventType = getEventType(payload);
    console.warn(
        `The size of ${eventType} - ${eventName} tracking event is ${messageSize} B which is close to the 32 kB limit. Events which are above the limit are discarded.`
    );
};

const logError = (payload, messageSize) => {
    const eventName = getEventName(payload);
    const eventType = getEventType(payload);
    const message = `The size of ${eventType} - ${eventName} tracking event ${messageSize} B reached the 32 kB limit and will be discarded by Segment.`;

    if (typeof onError === 'function') {
        onError({ message: message, event: payload.obj, eventName: eventName, eventType: eventType });
    }
};

const getEventName = (paylod) => {
    return paylod?.obj?.event ?? 'N/A';
};

const getEventType = (paylod) => {
    return paylod?.obj?.type ?? 'N/A';
};

const getMiddlewareDefinitions = () => {
    return [messageSizeValidationMiddleware];
};

const initialize = (config) => {
    onError = config.onError;
};

module.exports = {
    getSourceMiddlewares: getMiddlewareDefinitions,
    init: initialize
};
