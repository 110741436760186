let Glob = createGlob();

function isTrackingInitialized() {
    return Glob.trackingInitialized === true;
}

function setTrackingInitialized() {
    Glob.trackingInitialized = true;
}

function setTracking(value) {
    if (value) {
        Glob.tracking = value;
    }
}

function getTracking() {
    return Glob.tracking;
}

function createGlob() {
    let globalObject = {};

    // https://www.gatsbyjs.org/docs/debugging-html-builds/#how-to-check-if-window-is-defined
    if (typeof window !== 'undefined') {
        globalObject = window;
    }

    globalObject.trackingInitialized = globalObject.trackingInitialized || false;

    return globalObject;
}

function reset() {
    Glob = createGlob();
}

module.exports = {
    isTrackingInitialized: isTrackingInitialized,
    setTrackingInitialized: setTrackingInitialized,
    setTracking: setTracking,
    getTracking: getTracking,
    reset: reset
};
